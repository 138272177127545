export const getLocalStorageLanguage = (
  supportedLanguages = ['de', 'en', 'es', 'fr', 'it', 'nl']
) => {
  const defaultLanguage = 'en';
  try {
    const lng = localStorage.getItem('i18nextLng');

    if (!lng) {
      return defaultLanguage;
    }

    const languageCode = lng.split('-')[0];

    if (supportedLanguages.includes(languageCode)) {
      return languageCode;
    } else {
      return defaultLanguage;
    }
  } catch (error) {
    return defaultLanguage;
  }
};
